import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

// styles
const pageStyles = {
  color: "white",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  margin: "auto",
  maxWidth: "1024px",
  backgroundColor: "#474747",
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <main style={pageStyles}>
        <title>{frontmatter.title}</title>
        <div className="blog-post-container">
          <div className="blog-post">
            <h1>{frontmatter.title}</h1>
            <h2>{frontmatter.date}</h2>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }`
