import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"

// styles
const pageStyles = {
  color: "white",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  margin: "auto",
  maxWidth: "1024px",
  backgroundColor: "#474747",
  fontSize: "150%",
}

const center = {
  textAlign: "center"
}

const largerCenter = {
  textAlign: "center",
  fontSize: "200%",
}

// markup
const IndexPage = () => {
  return (
    <Layout>
      <main style={pageStyles}>
        <div style={largerCenter}>Some things to check out while we're still cloistered away in the monestary in Mount Anarch</div>
        <div>&nbsp;</div>
        <div style={center}><a href="https://thenormalblokespodcast.com/2021/08/16/episode-94-welcome-to-the-brotherhood-grey-knights-review/">Normal Blokes GK Episode</a></div>
        <div style={center}><a href="https://www.goonhammer.com/codex-grey-knights-9th-edition-the-goonhammer-review/">Goonhammer Review</a></div>
      </main>
    </Layout>
  )
}

export default IndexPage
