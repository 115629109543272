import * as React from "react"
import Header from "./header"
import "./styles.scss"

export default function Layout({ children, pageInfo }) {
  return (
    <div style={{ margin: `0 auto`, maxWidth: 1024, padding: `0 1rem` }}>
    <Header siteTitle="Adeptus Malleus" />
      {children}
    </div>
  )
}