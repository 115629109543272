import * as React from "react"
// import { Link } from "gatsby"
import "../../components/styles.scss"
import { graphql, Link, withPrefix } from 'gatsby'

// styles
const pageStyles = {
  color: "white",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  margin: "auto",
  maxWidth: "1024px",
  backgroundColor: "#474747",
}

// markup
const ArticlesPage = props => {
  console.log(props)
  const { data } = props
  const edges = data.allMarkdownRemark.edges
  console.log(edges)


  var articles
  articles = edges.map(edge =>
    <div className="row" key={edge.node.frontmatter.slug}>
      <Link to={edge.node.frontmatter.slug}>{edge.node.frontmatter.title}</Link>
    </div>
  )

  return (
    <main style={pageStyles}>
      <title>The Chamber of Purity</title>
      A list of articles...
      {articles}
    </main>
  )
}

export default ArticlesPage

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`